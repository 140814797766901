exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-ru-tsx": () => import("./../../../src/pages/index.ru.tsx" /* webpackChunkName: "component---src-pages-index-ru-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-policy-ru-tsx": () => import("./../../../src/pages/legal/policy.ru.tsx" /* webpackChunkName: "component---src-pages-legal-policy-ru-tsx" */),
  "component---src-pages-legal-policy-tsx": () => import("./../../../src/pages/legal/policy.tsx" /* webpackChunkName: "component---src-pages-legal-policy-tsx" */),
  "component---src-pages-legal-terms-ru-tsx": () => import("./../../../src/pages/legal/terms.ru.tsx" /* webpackChunkName: "component---src-pages-legal-terms-ru-tsx" */),
  "component---src-pages-legal-terms-tsx": () => import("./../../../src/pages/legal/terms.tsx" /* webpackChunkName: "component---src-pages-legal-terms-tsx" */)
}

